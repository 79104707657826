import Swiper from 'swiper';
import { Keyboard, Navigation, Parallax } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/parallax';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

addEventListener('DOMContentLoaded', (event) => {
	const swiperHero = new Swiper('.swiper--hero', {
		modules: [Keyboard, Navigation, Parallax],
		speed: 500,
		spaceBetween: 24,
		loop: true,
		watchSlidesProgress: true,
		grabCursor: true,
		keyboard: {
			enabled: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		parallax: {
			enabled: true,
		},
		on: {
			init: function () {
				var counts = document.querySelectorAll('.number-of-slides');
				counts.forEach((item) => {
					item.innerHTML = this.slides.length;
				});
			},
		},
		// breakpoints: {
		// 	992: {
		// 		slidesPerView: 1.6,
		// 	},
		// },
	});

	const siteHeader = document.querySelector(".site-header");
	const headerToggle = document.querySelector(".site-header__burger");
	const headerWrapper = document.querySelector(".header__wrapper");
	const headerInner = document.querySelector(".header__inner");
	const backdrop = document.querySelector(".backdrop");
	headerToggle.addEventListener("click", () => {
		siteHeader.classList.toggle("mobile-nav--active");
		backdrop.classList.toggle("backdrop--show");
		headerToggle.classList.toggle("header__toggle--active");
		document.body.classList.toggle("no-scroll");
		headerWrapper.style.height =
			headerWrapper.getBoundingClientRect().height === 0
				? "calc(100vh - 87px)"
				: "0px";
	});

	Fancybox.bind('[data-fancybox="gallery"]');
});
